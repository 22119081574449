.chat-content {
  display: flex; 
  justify-content: center;
}

.chat-container {
  padding:0;
  background-color: #FFF; 
  box-shadow: 0 5px 30px rgba(0,0,0,0.10), 0 6px 6px rgba(0,0,0,0.10);
  height: 700px;
  max-width: 1100px; 
  width: 1100px;
  margin-top: 20px;
  display: flex;
  position: relative;
}

.chat-container-start {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 15;
  background-color: white;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.chat-discussions {
  width: 35%;
  height: 700px;
  /* box-shadow: 0px 8px 10px rgba(0,0,0,0.20); */
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: #F7F7F7;
  display: inline-block;
}

.chat-discussions .chat-discussion {
  width: 100%;
  height: 90px;
  background-color: #F3F3F3;
  border-bottom: solid 1px #E0E0E0;
  display:flex;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.chat-discussions .search {
  display:flex;
  align-items: center;
  justify-content: center;
  color: #E0E0E0;
}

.chat-discussions .search .searchbar {
  height: 40px;
  background-color: #FFF;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #EEEEEE;
  display:flex;
  align-items: center;
  cursor: pointer;
}

.chat-discussions .search .searchbar input {
  margin-left: 15px;
  height:38px;
  width:100%;
  border:none;
  font-family: 'Montserrat', sans-serif;
}

.chat-discussions .search .searchbar *::-webkit-input-placeholder {
    color: #E0E0E0;
}
.chat-discussions .search .searchbar input *:-moz-placeholder {
    color: #E0E0E0;
}
.chat-discussions .search .searchbar input *::-moz-placeholder {
    color: #E0E0E0;
}
.chat-discussions .search .searchbar input *:-ms-input-placeholder {
    color: #E0E0E0;
}

.message-active {
  background-color: #ECF1FF;
  border-right: solid 5px #6780cc;
  box-shadow: inset 0 0 15px #d8d8d8;
}

.unread {
  background-color: #ffeeee !important;
}

.chat-discussions .chat-discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat-online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8BC34A;
  border-radius: 13px;
  border: 3px solid #FAFAFA;
}

.chat-desc-contact {
  /* height: 43px; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-desc-contact-header {
  display: flex; 
  justify-content: space-between;
}

.chat-discussions .chat-discussion .name {
  margin: 0 0 0 20px;
  font-family:'', sans-serif;
  font-size: 11pt;
  color:#000;
  font-weight: 500;
}

.chat-discussions .chat-discussion .message {
  margin: 6px 0 0 20px;
  font-family:'Montserrat', sans-serif;
  font-size: 9pt;
  color:#515151;
}

.chat-timer {
  margin-right: 5px;
  font-family:'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #BBB;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
  min-width: 60px;
  text-align: center;
}

.chat-window {
  width: 100%;
  position: relative;
}

.chat-header {
  background-color: #FFF;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
  display:flex;
  align-items: center;
}

.chat-window .chat-header .icon {
  margin-left: 30px;
  color:#515151;
  font-size: 14pt;
}

.chat-window .chat-header .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family:'Montserrat', sans-serif;
  font-size: 13pt;
  color:#515151;
}

.chat-window .chat-header .right {
  position: absolute;
  right: 40px;
}

.chat-window .messages-chat 
{
  margin-top: 2px;
  margin-bottom: 2px;
  overflow: hidden;
  height: 530px;
  overflow-y: scroll;
}

.chat-window .messages-chat .message {
  display:flex;
  align-items: center;
  margin-bottom: 8px;
}

.chat-window .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat-window .messages-chat .text {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}

.chat-text-only {
  margin-left: 45px;
}

.chat-time {
  font-size: 10px;
  color:lightgrey;
  margin-bottom:10px;
  margin-left: 85px;
}

.chat-response-time {
  float: right;
  margin-right: 40px !important;
}

.chat-response {
  float: right;
  margin-right: 0px !important;
  margin-left:auto;
}

.chat-response .text {
  background-color: #e3effd !important;
}

.footer-chat {
  width: 100%;
  height: 80px;
  display:flex;
  align-items: center;
  position:absolute;
  bottom: 0;
  background-color: transparent;
  border-top: 2px solid #EEE;
  
}

.chat-window .footer-chat .icon {
  /* margin-left: 30px; */
  color:#C0C0C0;
  font-size: 14pt;
}

.chat-window .footer-chat .send {
  color:#fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}

.chat-window .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family:'Montserrat', sans-serif;
  font-size: 13pt;
  color:#515151;
}

.chat-window .footer-chat .right {
  position: absolute;
  right: 40px;
}

.chat-window-menu {
  position: absolute; 
  top: 51px; 
  right: 43px; 
  left: auto;
}

.chat-write-message {
  border:none !important;
  width:60%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}
.footer-chat input *:-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}
.footer-chat input *::-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
  margin-left:5px;
}
.footer-chat input *:-ms-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}

.chat-clickable {
  cursor: pointer;
  padding: 7px;
}

#chat-container-start-btn {
  width: 300px; 
  text-align: center;
  color: #03a9f4;
  background-color: #fff!important;
  border: 1px solid #bfcbd9!important;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 15px;
  outline-offset: 0;
  -webkit-transition: all .15s ease-in-out;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  letter-spacing: 0.3px;
  font-family: 'Montserrat', sans-serif;
}

#archive-chat {
  cursor: pointer;
}

.chat-message {
  margin-top:10px;
}

.chat-profile-box {
  width: 100%;
   height: 100%;
   position: absolute;
   z-index: 5;  
}

.website-list-box {
  background-color: #FFF;
  box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
  display:flex;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  max-width: 1200px;
  width: 100%;
  height: 100%;
}

.website-item {
  padding: 12px 18px;
  margin: 12px 18px;
  border-radius: 16px;
  box-shadow:  0 0 10px #d8d8d8;
  border: 2px solid #999;
  /* transition: color 300ms ease, background-color 300ms ease, box-shadow 300ms ease; */
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.website-item-active {
  padding: 12px 18px;
  margin: 12px 18px;
  border-radius: 16px;
  box-shadow: inset 0 0 10px #d8d8d8 !important;
  border: 2px solid #999;
  /* transition: color 300ms ease, background-color 300ms ease, box-shadow 300ms ease; */
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  background-color: #ddd;
}

.website-list-empty {
  width: 100%;
  text-align: center;
  font-size: 24px;
}